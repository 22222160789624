@media (max-width: 767px) {
  .price-block-windows-item {
    margin: auto;
    padding-bottom: 40px;
  }
  .window-actions {
    margin: 24px 0 40px;
  }
}
@media (min-width: 768px) {
  .price-block-windows-item {
    padding-bottom: 80px;
    min-height: 300px;
  }

  .window-actions {
    position: absolute;
    bottom: 10px;
  }
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-color: rgba(0, 0, 0, 0.5);
  width: 40px;
  height: 40px;
  background-size: 12px;
  border-radius: 20px;
  transition: all 0.2s;
}

.carousel-control-prev {
  &:hover {
    & .carousel-control-prev-icon {
      background-color: var(--color-deepskyblue);
    }
  }
}

.carousel-control-next {
  &:hover {
    & .carousel-control-next-icon {
      background-color: var(--color-deepskyblue);
    }
  }
}

#okna > .conteiner {
  padding: 0 30px;
}

.price {
  font-size: 36px;
  letter-spacing: -0.0375em;

  @media (width <= 420px) {
    font-size: 30px;
  }

  & span.rub {
    font-size: 0.625em;
    margin-top: 0.25em;
    letter-spacing: -0.025em;

    &:after {
      content: none;
    }
  }
}
