.blue-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 364px;
  background-color: var(--color-deepskyblue);
  padding: 24px 24px 16px;
  gap: 24px;
  z-index: 0;

  & .errorsContainer {
    color: #000000;
    background: #ffdb8c;
    border: 1px solid #ffe4af;
    width: 100%;
  }

  & .private-policy {
    color: rgba(255, 255, 255, 0.66);

    & > a {
      color: rgba(255, 255, 255, 0.9);

      &:hover,
      &:active {
        color: rgba(255, 255, 255, 1);
      }
    }
  }
}

.blue-card__title-text {
  position: relative;
  line-height: 120%;
  font-weight: 500;
  display: inline-block;
  width: 316px;
}

.tag {
  border-radius: 20px;
  border: 1px solid var(--color-white);
  display: flex;
  flex-direction: row;
  padding: 4px 8px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 16px;
}

.tag-image {
  width: 12px;
  height: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > img,
  & > .frame-child {
    position: relative;
    width: 10px;
    height: 10px;
  }
}

.tag-text {
  position: relative;
  line-height: 120%;
  font-weight: 500;
}

.blue-card__title {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 12px;
}

.main-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.75);
}

.shadow {
  position: absolute;
  margin: 0 !important;
  top: calc(50% - 187px);
  left: -24px;
  background-color: var(--color-midnightblue-100);
  width: 24px;
  height: 374px;
  z-index: 1;
}

.formcard {
  position: relative;
  display: flex;
  flex-direction: row;
}

.main-card-form {
  display: flex;
  flex-direction: row;
  font-size: 24px;
  color: var(--color-white);
}

@media (max-width: 1200px) {
}

@media (max-width: 900px) {
  .main-card-form {
    flex: 1;
    padding-left: 0;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    width: auto;
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (max-width: 420px) {
  .blue-card__title-text {
    align-self: stretch;
    width: auto;
  }

  .tag {
    width: auto;
    align-self: unset;
  }

  .main-form,
  .form-content,
  .blue-card__title {
    align-self: stretch;
    width: auto;
  }

  .blue-card,
  .formcard {
    flex: 1;
  }

  .main-card-form {
    align-self: stretch;
    width: 100%;
    padding: 0;
  }
}
