.main-text {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 429px;
  align-items: flex-start;
  justify-content: space-between;
}

.main-call {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  text-align: center;
  font-size: 14.81px;
}

.message {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 40px;
  max-width: 558px;
}

.title {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}

.h1 {
  margin: 0;
  position: relative;
  font-family: inherit;
  align-self: stretch;
  font-size: inherit;
  font-size: 56px;
  line-height: 130%;
  font-weight: 600;
}

.h2 {
  margin: 0;
  position: relative;
  font-family: inherit;
  font-size: 28px;
  line-height: 150%;
  font-weight: 600;

  &.h2-big {
    font-size: 40px;
    line-height: 1.3;
    font-weight: 600;

    @media (max-width: 420px) {
      font-size: 32px;
    }
  }

  &.h2-mb-1 {
    margin-bottom: 40px;
  }
}

.h3 {
  margin: 0;
  position: relative;
  font-size: 20px;
  line-height: 150%;
  font-weight: 600;
  font-family: inherit;
}

.h2,
.h3,
.h1 {
  text-align: left;
  text-transform: none;
  margin: 0;
  padding: 0;

  &.mg {
    margin-top: 0.5em;
    margin-bottom: 1em;
  }
}

.description {
  max-width: 500px;
}

.address {
  position: relative;
  line-height: 150%;
}

@media (max-width: 1200px) {
  .main-text {
    align-items: flex-start;
    justify-content: space-between;
    gap: 0;
  }
}

@media (max-width: 960px) {
  .h2,
  .h3,
  .h1 {
    text-align: center;
  }

  .message {
    gap: 16px;
  }

  .main-text {
    height: auto;
    gap: 40px;
    align-items: center;
    justify-content: flex-start;
    flex: unset;
    align-self: stretch;
  }
}

@media (max-width: 420px) {
  .h1 {
    font-size: 46px;
  }

  .h2 {
    font-size: 24px;
  }

  .main-call {
    align-self: stretch;
    width: auto;
  }

  .main-text {
    height: auto;
    align-items: center;
    justify-content: flex-start;
  }
}
