* {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="button"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="reset"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

body {
  font-family: var(--font-family);
  color: var(--color-black);
  background-color: var(--color-white);
  margin: 0;
  font-size: 16px;
  position: relative;
}

:root {
  --font-family: Geologica, sans-serif;
  --color-black: #000;
  --color-white: #fff;
  --color-gray-100: #00000080;
  --color-ghostwhite: #eff0fc;
  --color-midnightblue-100: #292e97;
  --color-deepskyblue: #00a8f0;
  --color-orangered-100: #f35806;
  --color-green: #51af09;
  --color-green-bg: #f1f5ed;
}

.colored {
  color: var(--color-green);
}



.button {
  background-color: var(--color-midnightblue-100);
  color: var(--color-white);
  cursor: pointer;
  border: 1px solid #0000;
  outline: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  min-width: 180px;
  max-height: 52px;
  padding: 16px 12px;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  transition: all .2s;
  display: flex;
  box-shadow: 0 2px 4px #00448c3d;
}

.button:focus, .button:hover {
  background-color: var(--color-midnightblue-100);
  color: #fff;
  outline: none;
  text-decoration: none;
  box-shadow: 0 4px 6px #00448c52;
}

.button--menu {
  background-color: var(--color-midnightblue-100);
  min-width: auto;
  padding: 16px 20px;
  display: none;
}

.button--menu .menu-icon--menu {
  display: inline-block;
}

.button--menu .menu-icon--close {
  display: none;
}

.button--menu.open .menu-icon--menu {
  display: none;
}

.button--menu.open .menu-icon--close {
  display: inline-block;
}

.button--cta {
  color: var(--color-white);
  background-color: #2bb109;
}

.button--cta:hover {
  background-color: #2ab009d9;
}

.button--cta:active {
  background-color: #2ab108;
}

.button--red {
  color: var(--color-white);
  background-color: #d21313;
}

.button--red:hover {
  background-color: #d21313d9;
}

.button--red:active {
  background-color: #d21313;
}

.button--orange {
  background-color: var(--color-orangered-100);
  color: var(--color-white);
}

.button--orange:hover {
  background-color: #f35806d9;
}

.button--orange:active {
  background-color: var(--color-orangered-100);
}

.button--blue {
  background-color: var(--color-deepskyblue);
  color: var(--color-white);
}

.button--blue:hover {
  background-color: #00a8f0d9;
}

.button--blue:active {
  background-color: var(--color-deepskyblue);
}

.button-main-call {
  cursor: pointer;
  background-color: var(--color-orangered-100);
  border: 0;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
  width: 364px;
  max-height: none;
  padding: 16px 24px;
  display: flex;
}

.button-main-call:hover {
  background-color: #f35806d9;
}

.button-main-call:active {
  background-color: var(--color-orangered-100);
}

.button-main-call__icon {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  position: relative;
  overflow: hidden;
}

.button-main-call__caption, .button-main-call__phone {
  font-size: 16px;
  line-height: 20px;
  font-family: var(--font-family);
  color: var(--color-white);
  text-align: left;
  position: relative;
}

.button-main-call__phone {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}

.button-main-call__content {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  display: flex;
}

.button--main {
  cursor: pointer;
  background-color: var(--color-midnightblue-100);
  border: 0;
  flex-direction: row;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  padding: 16px 10px;
  display: flex;
}

.button--main:hover {
  background-color: #292e96d9;
}

.button--main:active {
  background-color: #292e96;
}

@media (max-width: 1200px) {
  .button.button--menu {
    display: flex;
  }
}

@media (max-width: 420px) {
  .button-main-call__caption {
    align-self: stretch;
    width: auto;
    font-size: 12px;
  }

  .button-main-call__phone {
    align-self: stretch;
    width: auto;
    font-size: 22px;
  }

  .button-main-call__content {
    flex: 1;
  }

  .button-main-call {
    align-self: stretch;
    gap: 16px;
    width: auto;
    padding-left: 16px;
    padding-right: 16px;
  }

  .button--menu, .button--cta {
    padding: 8px 12px;
  }
}

input[type="text"], input[type="tel"], input[type="email"] {
  color: var(--color-black);
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: none;
  flex: 100%;
  align-self: stretch;
  width: auto;
  min-width: 100px;
  max-width: 400px;
  padding: 16px 12px;
  font-size: 16px;
  line-height: 20px;
  transition: box-shadow, border .2s;
  box-shadow: 0 0 #0000;
}

:is(input[type="text"], input[type="tel"], input[type="email"]):hover {
  border-color: #333;
}

:is(input[type="text"], input[type="tel"], input[type="email"]):active, :is(input[type="text"], input[type="tel"], input[type="email"]):focus {
  box-shadow: 0 0 0 2px var(--color-orangered-100);
  border-color: var(--color-orangered-100);
  outline: 0;
}

input.main-input {
  color: var(--color-black);
  border: 1px solid var(--color-white);
  background-color: var(--color-white);
  padding: 16px;
  font-size: 18px;
  font-weight: 500;
}

input.main-input:hover {
  border: 1px solid var(--color-orangered-100);
}

input.main-input:active, input.main-input:focus {
  border: 1px solid var(--color-orangered-100);
  outline: 0;
}

.form-content {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  display: flex;
}

@media (min-width: 411px) {
  .form-content.form-content--row {
    flex-direction: row;
  }
}

.fields {
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  gap: 8px;
  display: flex;
}

@media (min-width: 411px) {
  .fields.fields--row {
    flex-direction: row;
  }
}

.fields input {
  width: 100%;
  margin: auto;
}

.private-policy {
  color: var(--color-gray-100);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.zayavka-open-txt {
  align-items: center;
  min-height: 60px;
  display: flex;
}

.open-form {
  margin: 24px 0;
}

.open-form.open-form--vertical {
  background-color: #fff;
  max-width: 460px;
  padding: 24px;
}

.open-form.open-form--container {
  max-width: 400px;
}

.open-form .form-header {
  color: var(--color-black);
  background-color: var(--color-green-bg);
  border-radius: 20px;
  padding: 24px;
}

.open-form .form-header h3 {
  color: var(--color-green);
}

#profil-dla-okon, #okna, #photo-rabot, #otzivi, #osteclenie {
  scroll-margin-top: 132px;
}

@media (max-width: 650px) {
  :is(#profil-dla-okon, #okna, #photo-rabot, #otzivi, #osteclenie) {
    scroll-margin-top: 226px;
  }
}

.title-bar {
  z-index: 1000;
  -webkit-backdrop-filter: blur(64px);
  backdrop-filter: blur(64px);
  background-color: #ffffffd1;
  border-bottom: 1px solid #0000001a;
  flex-direction: row;
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  gap: 32px;
  width: 100%;
  max-width: 100vw;
  padding: 12px 40px 20px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 10px 40px #0000000f;
}

.title-bar-content-main {
  color: var(--color-white);
  flex-direction: row;
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  gap: 32px;
  font-size: 16px;
  display: flex;
}

.title-bar-content-menu-mobile {
  color: var(--color-black);
  align-self: stretch;
  font-size: 18px;
  display: none;
  position: relative;
}

.title-bar-content-menu-mobile.open {
  display: flex;
}

.title-bar-content-menu-mobile.open .menu-mobile {
  display: flex;
}

.title-bar-content-top {
  text-align: center;
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.title-bar-content {
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  display: flex;
}

.logo-icon {
  width: 99px;
  height: 60px;
  position: relative;
}

.logo-place {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 148px;
  display: flex;
}

.ic-location {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  position: relative;
  overflow: hidden;
}

.bar-info-item {
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  line-height: 16px;
  display: flex;
  position: relative;
}

.bar-info-item > span {
  color: var(--color-gray-100);
}

.bar-info-item > .info-tel {
  color: var(--color-deepskyblue);
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-decoration: none;
}

.bar-info-item > .info-gray {
  color: var(--color-gray-100);
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
}

.div3 {
  line-height: 20px;
  display: none;
  position: relative;
}

.menu-icon {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: hidden;
}

.menu {
  color: var(--color-black);
  flex-direction: row;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0;
  margin-left: -12px;
  font-size: 18px;
  display: flex;
  position: relative;
}

.menu-mobile {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  font-size: 18px;
  display: flex;
  position: relative;
}

.menu-mobile .menu-item {
  align-self: stretch;
  display: flex;
}

.menu-mobile .menu-item a {
  flex: 100%;
}

.menu-item {
  position: relative;
}

.menu-item > a {
  color: var(--color-black);
  background-color: #0000;
  padding: 12px;
  text-decoration: none;
  transition: all .2s;
  display: inline-flex;
  box-shadow: inset 0 0 #0000000a;
}

.menu-item > a:hover {
  color: var(--color-deepskyblue);
  box-shadow: inset 0 -4px 0 var(--color-deepskyblue);
}

.bar-actions {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  display: flex;
}

@media (max-width: 1280px) {
  .logo-place {
    align-self: unset;
    width: auto;
  }
}

@media (max-width: 1200px) {
  .menu, .menu-mobile {
    display: none;
  }

  .title-bar-content-main {
    justify-content: space-between;
    align-items: center;
    gap: 32px;
  }
}

@media (max-width: 767px) {
  .bar-actions, .button--cta {
    flex: 1;
  }

  .title-bar {
    gap: 16px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .title-bar-content-top {
    flex-direction: column;
  }

  .title-bar-content-main {
    gap: 16px;
  }
}

@media (max-width: 650px) {
  .bar-actions, .button--cta {
    flex: 1;
  }

  .title-bar {
    flex-direction: column;
  }

  .title-bar-content {
    flex: unset;
    align-self: stretch;
  }
}

@media (max-width: 420px) {
  .logo-icon {
    width: auto;
    height: 48px;
  }

  .title-bar {
    gap: 12px;
    padding: 8px 12px;
  }

  .title-bar-content-main {
    gap: 12px;
  }

  .bar-info-item {
    font-size: 12px;
    font-weight: 600;
  }

  .bar-info-item > .info-tel {
    font-size: 14px;
    font-weight: 600;
  }

  .bar-info-item > .info-gray {
    font-size: 12px;
    font-weight: 600;
  }
}

.blue-card {
  background-color: var(--color-deepskyblue);
  z-index: 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  max-width: 364px;
  padding: 24px 24px 16px;
  display: flex;
}

.blue-card .errorsContainer {
  color: #000;
  background: #ffdb8c;
  border: 1px solid #ffe4af;
  width: 100%;
}

.blue-card .private-policy {
  color: #ffffffa8;
}

.blue-card .private-policy > a {
  color: #ffffffe6;
}

.blue-card .private-policy > a:hover, .blue-card .private-policy > a:active {
  color: #fff;
}

.blue-card__title-text {
  width: 316px;
  font-weight: 500;
  line-height: 120%;
  display: inline-block;
  position: relative;
}

.tag {
  border: 1px solid var(--color-white);
  border-radius: 20px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 4px 8px;
  font-size: 16px;
  display: flex;
}

.tag-image {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
  display: flex;
}

.tag-image > img, .tag-image > .frame-child {
  width: 10px;
  height: 10px;
  position: relative;
}

.tag-text {
  font-weight: 500;
  line-height: 120%;
  position: relative;
}

.blue-card__title {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  display: flex;
}

.main-form {
  color: #ffffffbf;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  font-size: 12px;
  display: flex;
}

.shadow {
  background-color: var(--color-midnightblue-100);
  z-index: 1;
  width: 24px;
  height: 374px;
  position: absolute;
  top: calc(50% - 187px);
  left: -24px;
  margin: 0 !important;
}

.formcard {
  flex-direction: row;
  display: flex;
  position: relative;
}

.main-card-form {
  color: var(--color-white);
  flex-direction: row;
  font-size: 24px;
  display: flex;
}

@media (max-width: 900px) {
  .main-card-form {
    flex: 1;
    justify-content: center;
    align-self: stretch;
    align-items: center;
    width: auto;
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (max-width: 420px) {
  .blue-card__title-text {
    align-self: stretch;
    width: auto;
  }

  .tag {
    align-self: unset;
    width: auto;
  }

  .main-form, .form-content, .blue-card__title {
    align-self: stretch;
    width: auto;
  }

  .blue-card, .formcard {
    flex: 1;
  }

  .main-card-form {
    align-self: stretch;
    width: 100%;
    padding: 0;
  }
}

.benefit {
  flex-direction: row;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  padding: 0 56px 24px 0;
  display: flex;
}

.benefit-icon {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  position: relative;
  overflow: hidden;
}

.benefit-content {
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  display: flex;
}

.benefit--vertical {
  flex: 1;
  padding: 0 56px 24px 0;
}

.benefit--vertical > .benefit-content {
  gap: 16px;
  width: 100%;
}

.benefit-content__title {
  align-self: stretch;
  margin: 0;
  font-family: inherit;
  font-size: 24px;
  font-weight: 600;
  line-height: 120%;
  position: relative;
}

.benefit-content__text {
  color: var(--color-gray-100);
  align-self: stretch;
  font-size: 16px;
  line-height: 150%;
  position: relative;
}

@media (max-width: 960px) {
  .benefit {
    padding-bottom: 0;
  }
}

@media (max-width: 845px) {
  .benefit {
    flex: unset;
    align-self: stretch;
  }

  .benefit--vertical {
    flex-direction: row;
    flex: unset;
    align-self: stretch;
    gap: 24px;
    padding-bottom: 0;
  }

  .benefit--vertical > .f-row {
    flex-direction: column;
  }
}

@media (max-width: 420px) {
  .benefit-content__title {
    font-size: 18px;
  }

  .benefit--vertical {
    flex: unset;
    flex: unset;
    align-self: stretch;
    padding-bottom: 0;
  }
}

.banner {
  background-color: #f2f2fd;
  border-radius: 20px;
  width: 100%;
  margin: 32px auto;
  padding: 32px;
}

.section {
  justify-content: center;
  width: 100%;
  padding: 40px 0;
}

.section__container {
  gap: 24px;
  width: 100%;
  max-width: 1140px;
  margin: auto;
  position: relative;
}

.section--content {
  padding: 0;
  display: flex;
}

.section--content > .section__container {
  padding: 64px 0;
}

.section--main {
  margin-top: 120px;
  padding: 0;
  display: flex;
}

.section--main > .section__container {
  background-image: url("public/sectionmain@3x.f3349d27.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: row;
  padding: 64px 0;
  display: flex;
}

.section--points {
  padding: 0;
  display: flex;
}

.section--points > .section__container {
  flex-direction: row;
  gap: 2px;
  padding: 0 0 40px;
  display: flex;
}

.section--benefit {
  padding: 0;
  display: flex;
}

.section--benefit > .section__container {
  flex-direction: column;
  gap: 56px;
  padding: 40px 0 64px;
  display: flex;
}

.section--open-form {
  padding: 0;
  display: flex;
}

.section--open-form > .section__container {
  padding: 40px 0;
}

.section--bg {
  padding: 0;
  display: flex;
}

.section--bg > .section__container {
  padding: 120px 0 40px;
}

@media (min-width: 901px) {
  .section--bg {
    background-image: url("img/92daa34f4ec191eb626a57a6d001c026.95787bb8.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media (max-width: 900px) {
  .section--bg > .section__container {
    padding: 40px 0;
  }

  .section--bg .section__title-content {
    padding: 0;
  }
}

.section-title {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.section-title > .h2 {
  flex: 1;
  max-width: 849px;
  font-size: 40px;
  font-weight: 600;
  line-height: 1.3;
}

.section__title-content {
  padding: 48px 0;
}

@media (max-width: 1200px) {
  .section > .section__container {
    padding-left: 24px;
    padding-right: 24px;
  }

  .section.section--points > .section__container {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 900px) {
  .section.section--points > .section__container {
    flex-direction: column;
  }

  .section--main > .section__container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 24px;
  }

  .section--points {
    flex-direction: column;
    height: auto;
    padding-bottom: 0;
  }

  .section--benefit {
    padding-bottom: 40px;
  }
}

@media (max-width: 650px) {
  .section--main {
    margin-top: 208px;
  }
}

@media (max-width: 500px) {
  .section.section--points > .section__container, .section.section--points .f-col-container {
    flex-direction: column;
  }
}

@media (max-width: 420px) {
  .section--main {
    margin-top: 168px;
  }

  .section--main > .section__container {
    padding: 32px 16px;
  }

  .section--points {
    padding: 0 16px;
  }

  .section-title > .h2 {
    font-size: 30px;
  }

  .section--benefit > .section__container {
    gap: 32px;
    padding-top: 24px;
    padding-bottom: 28px;
  }
}

.main-text {
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-items: flex-start;
  height: 429px;
  display: flex;
}

.main-call {
  text-align: center;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  font-size: 14.81px;
  display: flex;
}

.message {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 40px;
  width: 100%;
  max-width: 558px;
  display: flex;
}

.title {
  flex-direction: column;
  gap: 4px;
  width: 100%;
  display: flex;
}

.h1 {
  font-family: inherit;
  font-size: inherit;
  align-self: stretch;
  margin: 0;
  font-size: 56px;
  font-weight: 600;
  line-height: 130%;
  position: relative;
}

.h2 {
  margin: 0;
  font-family: inherit;
  font-size: 28px;
  font-weight: 600;
  line-height: 150%;
  position: relative;
}

.h2.h2-big {
  font-size: 40px;
  font-weight: 600;
  line-height: 1.3;
}

@media (max-width: 420px) {
  .h2.h2-big {
    font-size: 32px;
  }
}

.h2.h2-mb-1 {
  margin-bottom: 40px;
}

.h3 {
  margin: 0;
  font-family: inherit;
  font-size: 20px;
  font-weight: 600;
  line-height: 150%;
  position: relative;
}

.h2, .h3, .h1 {
  text-align: left;
  text-transform: none;
  margin: 0;
  padding: 0;
}

:is(.h2, .h3, .h1).mg {
  margin-top: .5em;
  margin-bottom: 1em;
}

.description {
  max-width: 500px;
}

.address {
  line-height: 150%;
  position: relative;
}

@media (max-width: 1200px) {
  .main-text {
    justify-content: space-between;
    align-items: flex-start;
    gap: 0;
  }
}

@media (max-width: 960px) {
  .h2, .h3, .h1 {
    text-align: center;
  }

  .message {
    gap: 16px;
  }

  .main-text {
    flex: unset;
    justify-content: flex-start;
    align-self: stretch;
    align-items: center;
    gap: 40px;
    height: auto;
  }
}

@media (max-width: 420px) {
  .h1 {
    font-size: 46px;
  }

  .h2 {
    font-size: 24px;
  }

  .main-call {
    align-self: stretch;
    width: auto;
  }

  .main-text {
    justify-content: flex-start;
    align-items: center;
    height: auto;
  }
}

.point-card {
  background-color: var(--color-ghostwhite);
  flex-direction: row;
  flex: 1;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  gap: 8px;
  padding: 20px;
  display: flex;
}

.point-card__icon {
  flex-shrink: 0;
  width: 56px;
  height: 56px;
  position: relative;
  overflow: hidden;
}

.point-card__content {
  flex-direction: row;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 6px 0 0;
  display: flex;
}

.point-card__text {
  flex: 1;
  line-height: 150%;
  position: relative;
}

.f-col-container {
  flex-direction: row;
  flex: 1;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  gap: 2px;
  display: flex;
}

@media (max-width: 960px) {
  .point-card__text {
    font-size: 13px;
  }

  .f-col-container {
    flex: unset;
    align-self: stretch;
  }
}

@media (max-width: 420px) {
  .point-card {
    flex: unset;
    align-self: stretch;
    align-items: center;
    padding: 8px 16px;
  }

  .point-card__icon {
    width: 44px;
    height: 44px;
  }

  .f-col-container {
    flex-direction: column;
    height: auto;
  }
}

@media (max-width: 767px) {
  .price-block-windows-item {
    margin: auto;
    padding-bottom: 40px;
  }

  .window-actions {
    margin: 24px 0 40px;
  }
}

@media (min-width: 768px) {
  .price-block-windows-item {
    min-height: 300px;
    padding-bottom: 80px;
  }

  .window-actions {
    position: absolute;
    bottom: 10px;
  }
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon, .carousel-control-prev-icon {
  background-color: #00000080;
  background-size: 12px;
  border-radius: 20px;
  width: 40px;
  height: 40px;
  transition: all .2s;
}

.carousel-control-prev:hover .carousel-control-prev-icon {
  background-color: var(--color-deepskyblue);
}

.carousel-control-next:hover .carousel-control-next-icon {
  background-color: var(--color-deepskyblue);
}

#okna > .conteiner {
  padding: 0 30px;
}

.price {
  letter-spacing: -.0375em;
  font-size: 36px;
}

@media (max-width: 420px) {
  .price {
    font-size: 30px;
  }
}

.price span.rub {
  letter-spacing: -.025em;
  margin-top: .25em;
  font-size: .625em;
}

.price span.rub:after {
  content: none;
}

.modal-form .main-form {
  color: var(--color-black);
}

.modal-form .openCallBackModal:before, .modal-form .openCallBackModal:after {
  background: linear-gradient(#00a8f0 0%, #292e97 100%);
}

.form-field > .text-wrapper {
  margin-bottom: 2px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.form-field.form-field--personal-data small {
  line-height: 13px;
}

.form-field input[type="checkbox"] {
  margin: 0;
}

.form-footer {
  width: 100%;
}

#recaptcha-container {
  display: none;
}

.mt-0 {
  margin-top: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.f-content-center {
  justify-content: center;
  display: flex;
}

.f-col {
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  gap: 24px;
  display: flex;
}

@media (min-width: 961px) {
  .f-col.f-col-33 > .f-row > * {
    width: 33.34%;
  }
}

.f-row {
  flex-direction: flex-row;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  gap: 24px;
  display: flex;
}

.site-content {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
}

@media (max-width: 1200px) {
  .site-content {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 960px) {
  .site-content, .f-col, .f-row {
    flex-direction: column;
  }
}

@media (max-width: 845px) {
  .f-row {
    flex-direction: column;
  }
}

@media (max-width: 420px) {
  .h2 {
    font-size: 28px;
  }

  .f-row {
    flex-direction: column;
  }
}

/*# sourceMappingURL=index.9222d63b.css.map */
