
.modal-form {
  & .main-form {
    color: var(--color-black);
  }

  & .openCallBackModal {
    &:before {
      background: linear-gradient(#00a8f0 0%, #292e97 100%);
    }
    &:after {
      background: linear-gradient(#00a8f0 0%, #292e97 100%);
    }
  }
}

.form-field {
  & > .text-wrapper {
    margin-bottom: 2px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
  }

  &.form-field--personal-data small {
    line-height: 13px;
  }

  & input[type="checkbox"] {
    margin: 0;
  }
}

.form-footer {
  width: 100%;
}

#recaptcha-container {
  display: none;
}

