@import "./css/reset.css";

/* @import './css/oknanaveka/main1.css';
@import './css/oknanaveka/main4.css';
@import './css/oknanaveka/main2.css';
@import './css/oknanaveka/main3.css'; */

@import "./css/global.css";

@import "./css/components/grid.css";
@import "./css/components/button.css";
@import "./css/components/input.css";
@import "./css/components/title-bar.css";
@import "./css/components/main-card-form.css";
@import "./css/components/benefit.css";

@import "./css/components/section.css";
@import "./css/components/main.css";
@import "./css/components/point-card.css";
@import "./css/components/windows-item.css";
@import "./css/components/modal-form.css";

.mt-0 {
  margin-top: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.f-content-center {
  display: flex;
  justify-content: center;
}

.f-col {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  align-self: stretch;
  gap: 24px;

  @media (min-width: 961px) {
    &.f-col-33 {
      & > .f-row {
        & > * {
          width: 33.34%;
        }
      }
    }
  }
}

.f-row {
  display: flex;
  flex-direction: flex-row;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  gap: 24px;
}

.site-content {
  flex-direction: column;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

@media (max-width: 1200px) {
  .site-content {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 960px) {
  .site-content {
    flex-direction: column;
  }

  .f-col {
    flex-direction: column;
  }

  .f-row {
    flex-direction: column;
  }
}

@media (max-width: 845px) {
  .f-row {
    flex-direction: column;
  }
}

@media (max-width: 420px) {
  .h2 {
    font-size: 28px;
  }

  .f-row {
    flex-direction: column;
  }
}
