.point-card {
  background-color: var(--color-ghostwhite);
  padding: 20px;
  gap: 8px;
  align-self: stretch;
  flex: 1;
  display: flex;
   flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.point-card__icon {
  position: relative;
  width: 56px;
  height: 56px;
  overflow: hidden;
  flex-shrink: 0;
}

.point-card__content {
  padding: 6px 0 0;
  flex: 1;
  display: flex;
   flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.point-card__text {
  position: relative;
  flex: 1;
  line-height: 150%;
}

.f-col-container {
  gap: 2px;
  align-self: stretch;
  flex: 1;
  display: flex;
   flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

@media (max-width: 960px) {
  .point-card__text {
    font-size: 13px;
  }

  .f-col-container {
    flex: unset;
    align-self: stretch;
  }
}

@media (max-width: 420px) {
  .point-card {
    flex: unset;
    align-self: stretch;
    padding: 8px 16px;
    align-items: center;
  }

  .point-card__icon {
    width: 44px;
    height: 44px;
  }

  .f-col-container {
    flex-direction: column;
    height: auto;
  }
}