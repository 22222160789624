body {
  margin: 0;
  position: relative;
  font-family: var(--font-family);
  font-size: 16px;
  color: var(--color-black);
  background-color: var(--color-white);
}

:root {
  /* fonts */
  --font-family: Geologica, sans-serif;

  /* Colors */
  --color-black: #000;
  --color-white: #fff;
  --color-gray-100: rgba(0, 0, 0, 0.5);
  --color-ghostwhite: #eff0fc;
  --color-midnightblue-100: #292e97;
  --color-deepskyblue: #00a8f0;
  --color-orangered-100: #f35806;
  --color-green: #51af09;
  --color-green-bg: #f1f5ed;
}

.colored {
  color: var(--color-green);
}
