input[type="text"],
input[type="tel"],
input[type="email"] {
  padding: 16px 12px;
  font-size: 16px;
  line-height: 20px;
  border-radius: 4px;
  border: 1px solid #aaa;
  color: var(--color-black);
  width: auto;
  min-width: 100px;
  max-width: 400px;
  flex: 1 1 100%;
  align-self: stretch;
  box-shadow: 0 0 0 0 transparent;
  outline: none;
  transition: box-shadow, border 0.2s;

  &:hover {
    border-color: #333;
  }

  &:active,
  &:focus {
    box-shadow: 0 0 0 2px var(--color-orangered-100);
    border-color: var(--color-orangered-100);
    outline: 0;
  }
}

input.main-input {
  color: var(--color-black);
  border: 1px solid var(--color-white);
  background-color: var(--color-white);
  font-size: 18px;
  font-weight: 500;
  padding: 16px;

  &:hover {
    border: 1px solid var(--color-orangered-100);
  }

  &:active,
  &:focus {
    border: 1px solid var(--color-orangered-100);
    outline: 0;
  }
}

.form-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  width: 100%;

  &.form-content--row {
    @media (min-width: 411px) {
      flex-direction: row;
    }
  }
}

.fields {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  gap: 8px;

  &.fields--row {
    @media (min-width: 411px) {
      flex-direction: row;
    }
  }

  & input {
    margin: auto;
    width: 100%;
  }
}

.form-field {
}

.private-policy {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: var(--color-gray-100);
}

.zayavka-open-txt {
  min-height: 60px;
  align-items: center;
  display: flex;
}

.open-form {
  margin: 24px 0;

  &.open-form--vertical {
    max-width: 460px;
    padding: 24px;
    background-color: #fff;
  }

  &.open-form--container {
    max-width: 400px;
  }

  & .form-header {
    color: var(--color-black);
    padding: 24px;
    background-color: var(--color-green-bg);
    border-radius: 20px;

    & h3 {
      color: var(--color-green);
    }
  }
}
