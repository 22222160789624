.benefit {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  flex-direction: row;
  padding: 0 56px 24px 0;
  gap: 24px;
}

.benefit-icon {
  position: relative;
  width: 40px;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
}

.benefit-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}

.benefit--vertical {
  flex: 1;
  padding: 0 56px 24px 0;

  & > .benefit-content {
    width: 100%;
    /* max-width: 308px; */
    gap: 16px;
  }
}

.benefit-content__title {
  align-self: stretch;
  position: relative;
  margin: 0;
  font-size: 24px;
  line-height: 120%;
  font-weight: 600;
  font-family: inherit;
}

.benefit-content__text {
  align-self: stretch;
  position: relative;
  font-size: 16px;
  line-height: 150%;
  color: var(--color-gray-100);
}

@media (max-width: 960px) {
  .benefit {
    padding-bottom: 0;
  }
}

@media (max-width: 845px) {
  .benefit {
    flex: unset;
    align-self: stretch;
  }

  .benefit--vertical {
    flex-direction: row;
    gap: 24px;
    padding-bottom: 0;

    flex: unset;
    align-self: stretch;

    & > .f-row {
      flex-direction: column;
    }
  }
}

@media (max-width: 420px) {
  .benefit-content__title {
    font-size: 18px;
  }
  .benefit--vertical {
    flex: unset;
    align-self: stretch;
  }
  .benefit--vertical {
    padding-bottom: 0;

    flex: unset;
    align-self: stretch;
  }
}
