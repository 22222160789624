.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 180px;
  max-height: 52px;
  gap: 10px;
  padding: 16px 12px;
  background-color: var(--color-midnightblue-100);
  color: var(--color-white);
  font-size: 16px;
  line-height: 24px;
  border: 1px solid transparent;
  cursor: pointer;
  outline: none;
  box-shadow: 0 2px 4px rgb(0, 68, 140, 0.24);
  transition: all 0.2s;
  text-decoration: none;

  &:focus,
  &:hover {
    outline: none;
    box-shadow: 0 4px 6px rgb(0, 68, 140, 0.32);
    text-decoration: none;
    background-color: var(--color-midnightblue-100);
    color: #fff;
  }
}

.button--menu {
  display: none;
  min-width: auto;
  background-color: var(--color-midnightblue-100);
  padding: 16px 20px;

  & .menu-icon--menu {
    display: inline-block;
  }

  & .menu-icon--close {
    display: none;
  }

  &.open {
    & .menu-icon--menu {
      display: none;
    }

    & .menu-icon--close {
      display: inline-block;
    }
  }
}

.button--cta {
  background-color: #2bb109;
  color: var(--color-white);

  &:hover {
    background-color: rgba(42, 176, 9, 0.85);
  }

  &:active {
    background-color: #2ab108;
  }
}

.button--red {
  background-color: #d21313;
  color: var(--color-white);

  &:hover {
    background-color: rgb(210, 19, 19, 0.85);
  }

  &:active {
    background-color: #d21313;
  }
}

.button--orange {
  background-color: var(--color-orangered-100);
  color: var(--color-white);

  &:hover {
    background-color: rgba(243, 88, 6, 0.85);
  }

  &:active {
    background-color: var(--color-orangered-100);
  }
}

.button--blue {
  background-color: var(--color-deepskyblue);
  color: var(--color-white);

  &:hover {
    background-color: rgba(0, 168, 240, 0.85);
  }

  &:active {
    background-color: var(--color-deepskyblue);
  }
}

.button-main-call {
  cursor: pointer;
  border: 0;
  padding: 16px 24px;
  width: 364px;
  max-height: none;
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  background-color: var(--color-orangered-100);

  &:hover {
    background-color: rgba(243, 88, 6, 0.85);
  }

  &:active {
    background-color: var(--color-orangered-100);
  }
}

.button-main-call__icon {
  position: relative;
  width: 32px;
  height: 32px;
  overflow: hidden;
  flex-shrink: 0;
}
.button-main-call__caption,
.button-main-call__phone {
  position: relative;
  font-size: 16px;
  line-height: 20px;
  font-family: var(--font-family);
  color: var(--color-white);
  text-align: left;
}
.button-main-call__phone {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
}
.button-main-call__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;
}

.button--main {
  cursor: pointer;
  border: 0;
  padding: 16px 10px;
  background-color: var(--color-midnightblue-100);
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.button--main:hover {
  background-color: rgba(41, 46, 150, 0.85);
}

.button--main:active {
  background-color: #292e96;
}

@media (max-width: 1200px) {
  .button.button--menu {
    display: flex;
  }
}

@media (max-width: 420px) {
  .button-main-call__caption {
    align-self: stretch;
    width: auto;
    font-size: 12px;
  }

  .button-main-call__phone {
    align-self: stretch;
    width: auto;
    font-size: 22px;
  }

  .button-main-call__content {
    flex: 1;
  }

  .button-main-call {
    align-self: stretch;
    width: auto;
    gap: 16px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .button--menu {
    padding: 8px 12px;
  }

  .button--cta {
    padding: 8px 12px;
  }
}
