.banner {
  width: 100%;
  padding: 32px;
  border-radius: 20px;
  background-color: #f2f2fd;
  margin: 32px auto;
}

.section {
  justify-content: center;
  width: 100%;
  padding: 40px 0;
}

.section__container {
  position: relative;
  width: 100%;
  max-width: 1140px;
  gap: 24px;
  margin: auto;
}

.section--content {
  display: flex;
  padding: 0;

  & > .section__container {
    padding: 64px 0;
  }
}

.section--main {
  display: flex;
  margin-top: 120px;
  padding: 0;

  & > .section__container {
    display: flex;
    flex-direction: row;
    padding: 64px 0;

    background-image: url(/public/sectionmain@3x.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }
}

.section--points {
  display: flex;
  padding: 0;

  & > .section__container {
    display: flex;
    flex-direction: row;
    gap: 2px;
    padding: 0 0 40px;
  }
}

.section--benefit {
  display: flex;
  padding: 0;

  & > .section__container {
    display: flex;
    flex-direction: column;
    gap: 56px;
    padding: 40px 0 64px;
  }
}

.section--open-form {
  display: flex;
  padding: 0;

  & > .section__container {
    padding: 40px 0 40px;
  }
}

.section--bg {
  display: flex;
  padding: 0;

  & > .section__container {
    padding: 120px 0 40px;
  }

  @media (min-width: 901px) {
    background-image: url("/img/92daa34f4ec191eb626a57a6d001c026.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media (max-width: 900px) {
    & > .section__container {
      padding: 40px 0 40px;
    }

    .section__title-content {
      padding: 0;
    }
  }
}

.section-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  & > .h2 {
    flex: 1;
    font-size: 40px;
    line-height: 1.3;
    font-weight: 600;
    max-width: 849px;
  }
}

.section__title-content {
  padding: 48px 0 48px 0;
}

@media (max-width: 1200px) {
  .section {
    & > .section__container {
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  .section.section--points {
    & > .section__container {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

@media (max-width: 900px) {
  .section.section--points {
    & > .section__container {
      flex-direction: column;
    }
  }

  .section--main {
    & > .section__container {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 40px 24px;
    }
  }

  .section--points {
    height: auto;
    flex-direction: column;
    padding-bottom: 0;
  }

  .section--benefit {
    padding-bottom: 40px;
  }
}

@media (max-width: 650px) {
  .section--main {
    margin-top: 208px;
  }
}

@media (max-width: 500px) {
  .section.section--points {
    & > .section__container {
      flex-direction: column;
    }

    & .f-col-container {
      flex-direction: column;
    }
  }
}

@media (max-width: 420px) {
  .section--main {
    margin-top: 168px;

    & > .section__container {
      padding: 32px 16px;
    }
  }

  .section--points {
    padding: 0 16px;
  }

  .section-title {
    & > .h2 {
      font-size: 30px;
    }
  }

  .section--benefit {
    & > .section__container {
      gap: 32px;
      padding-top: 24px;
      padding-bottom: 28px;
    }
  }
}
