#profil-dla-okon,
#okna,
#photo-rabot,
#otzivi,
#osteclenie {
  scroll-margin-top: 132px;

  @media (max-width: 650px) {
    scroll-margin-top: 226px;
  }
}

.title-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  background-color: rgba(255, 255, 255, 0.82);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 12px 40px 20px;
  gap: 32px;
  max-width: 100vw;
  z-index: 1000;
  -webkit-backdrop-filter: blur(64px);
  backdrop-filter: blur(64px);
}

.title-bar {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.06);
}

.title-bar-content-main {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  gap: 32px;
  font-size: 16px;
  color: var(--color-white);
}

.title-bar-content-menu-mobile {
  align-self: stretch;
  position: relative;
  /* height: 320px; */
  display: none;
  /* background: var(--color-white); */
  font-size: 18px;
  color: var(--color-black);

  &.open {
    display: flex;

    & .menu-mobile {
      display: flex;
    }
  }
}

.title-bar-content-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  text-align: center;
}

.title-bar-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  flex-direction: column;
  gap: 12px;
}

.logo-icon {
  position: relative;
  width: 99px;
  height: 60px;
}

.logo-place {
  width: 148px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ic-location {
  position: relative;
  width: 16px;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
}

.bar-info-item {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  line-height: 16px;

  & > span {
    color: var(--color-gray-100);
  }

  & > .info-tel {
    color: var(--color-deepskyblue);
    font-size: 18px;
    text-decoration: none;
    line-height: 24px;
    font-weight: 600;
  }

  & > .info-gray {
    font-size: 14px;
    line-height: 16px;
    font-weight: 300;
    color: var(--color-gray-100);
  }
}

.div3 {
  position: relative;
  line-height: 20px;
  display: none;
}
.menu-icon {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}

.menu {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0;
  flex: 1;
  margin-left: -12px;
  font-size: 18px;
  color: var(--color-black);
}

.menu-mobile {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  font-size: 18px;

  & .menu-item {
    display: flex;
    align-self: stretch;

    & a {
      flex: 1 1 100%;
    }
  }
}

.menu-item {
  position: relative;

  & > a {
    display: inline-flex;
    padding: 12px;
    text-decoration: none;
    color: var(--color-black);
    background-color: transparent;
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.04);
    transition: all 0.2s;

    &:hover {
      color: var(--color-deepskyblue);
      /* background-color: rgba(0,0,0,.04); */
      box-shadow: inset 0 -4px 0 var(--color-deepskyblue);
    }
  }
}

.bar-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
}

@media (max-width: 1280px) {
  .logo-place {
    width: auto;
    align-self: unset;
  }
}

@media (max-width: 1200px) {
  .menu {
    display: none;
  }

  .menu-mobile {
    display: none;
  }

  .title-bar-content-main {
    gap: 32px;
    align-items: center;
    justify-content: space-between;
  }
}

@media (max-width: 767px) {
  .bar-actions,
  .button--cta {
    flex: 1;
  }

  .title-bar {
    gap: 16px;
    padding-left: 16px;
    padding-right: 16px;
  }
  .title-bar-content-top {
    flex-direction: column;
  }
  .title-bar-content-main {
    gap: 16px;
  }
}

@media (max-width: 650px) {
  .bar-actions,
  .button--cta {
    flex: 1;
  }

  .title-bar {
    flex-direction: column;
  }

  .title-bar-content {
    flex: unset;
    align-self: stretch;
  }
}

@media (max-width: 420px) {
  .logo-icon {
    width: auto;
    height: 48px;
  }

  .title-bar {
    padding: 8px 12px;
    gap: 12px;
  }

  .title-bar-content-main {
    gap: 12px;
  }

  .bar-info-item {
    font-size: 12px;
    font-weight: 600;

    & > .info-tel {
      font-size: 14px;
      font-weight: 600;
    }

    & > .info-gray {
      font-size: 12px;
      font-weight: 600;
    }
  }
}
